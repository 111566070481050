import React, { useEffect, useRef, useState } from "react"
import "./ContactComponentStyle.scss"
import InstagramImage from "../../assets/image/instagram.png"
import FacebookImage from "../../assets/image/facebook.png"
import MTVLogo from "../../assets/image/mtv.png"
import { ContactType } from "../../utils/Enum"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3"
import { checkRecaptcha } from "../../utils/Captcha"
const Checkbox = ({
  label,
  onChange,
  checked,
}: {
  label: string
  onChange: Function
  checked: boolean
}) => {
  const idGenerator = Math.floor(Math.random() * 100) + 1

  return (
    <div className="checkbox-wrapper">
      <input
        type="checkbox"
        id={`id-${idGenerator}`}
        name={`id-${idGenerator}`}
        checked={checked}
        onChange={event => onChange(event.target.checked as boolean)}
      />
      <label htmlFor={`id-${idGenerator}`} className="visible-checkbox">
        <div className="checkmark-wrapper">
          <div className="checkmark" />
        </div>
        <div
          className="checkbox-text"
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </label>
    </div>
  )
}

const ContactComponent = ({ isEnglish }: { isEnglish?: boolean }) => {
  const [siteKey] = useState("6Lcv1gAaAAAAAFWJ70y9lb9NQcMHe9KKy9Gu9CVz")
  const [cookiesAccepted, setCookiesAccepted] = useState(
    Cookies.get("AllowBCRDCookies") === "true"
  )
  const [recaptchaToken, setRecaptchaToken] = useState("")
  const recaptchaRef = useRef(null)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [currentSelection, setCurrentSelection] = useState<ContactType>(
    ContactType.TRAINING
  )
  const [showError, setShowError] = useState<boolean>(false)
  const [version, setVersion] = useState<number>(0)

  const verifyCallback = recaptchaToken => {
    setRecaptchaToken(recaptchaToken)
  }

  const resetMailData = (): any => {
    return {
      to: "info@barockcity.de",
      bout: {
        allGender: false,
        team: false,
        player: false,
        woman: false,
        man: false,
        date: "",
        where: "",
        name: "",
      },
      training: {
        mobile: "",
        shoeSize: "",
      },
      question: "",
      message: "",
      firstname: "",
      lastname: "",
      mail: "",
      phone: "",
    }
  }
  const [currentMessage, setCurrentMessage] = useState<any>(resetMailData())

  // keep the recatpcha up to date
  useEffect(() => {
    setTimeout(() => {
      if (recaptchaRef.current) recaptchaRef.current.execute()
    }, 120000)
  }, [recaptchaToken])

  // if cookies accepted show the google captcha
  useEffect(() => {
    if (cookiesAccepted) {
      loadReCaptcha(siteKey)
      setVersion(version + 1)
    }
  }, [])

  /**
   * Helper to determine if a string is valid
   *
   * @param input
   * @returns boolean if string is valid
   */
  const checkIfStringIsValid = (input: string): boolean => {
    if (input === null || input === undefined || input.length === 0)
      return false
    return true
  }

  /**
   * Helper to send an mail through php
   * TODO activate recaptcha
   *
   * @param type
   */
  const sendMessage = (type: ContactType): void => {
    if (
      !(
        checkIfStringIsValid(currentMessage.mail) &&
        checkIfStringIsValid(currentMessage.message)
      )
    ) {
      setShowError(true)
      return
    }
    setIsSending(true)
    setShowError(false)

    let localCurrentMessage: any = currentMessage
    switch (type) {
      case ContactType.BOUT:
        localCurrentMessage.to = "interleague@barockcity.de"
        break
      case ContactType.LEITUNG:
        localCurrentMessage.to = "vorstand@barockcity.de"
        break
      case ContactType.INFO:
        localCurrentMessage.to = "info@barockcity.de"
        break
      case ContactType.TRAINING:
      default:
        localCurrentMessage.to = "recruiting@barockcity.de"
        break
    }

    // send the damn mail
    /*checkRecaptcha(recaptchaRef, recaptchaToken).then(response => {
      if (response) {
        fetch("/php/mail.php", {
          method: "POST",
          body: JSON.stringify(currentMessage),
        })
          .then(result => {
            return result.json()
          })
          .then(response => {
            if (response.status === "ok") {
              setCurrentMessage(resetMailData())
              setVersion(version + 1)
            } else {
              setShowError(true)
            }
            setIsSending(false)
          })
      }
    })*/

    fetch("/php/mail.php", {
      method: "POST",
      body: JSON.stringify(localCurrentMessage),
    })
      .then(result => {
        return result.json()
      })
      .then(response => {
        if (response.status === "ok") {
          setCurrentMessage(resetMailData())
          setCurrentSelection(ContactType.TRAINING)
          setVersion(version + 1)
        } else {
          setShowError(true)
        }
        setIsSending(false)
      })
  }

  /**
   * Accept the cookies and rerenders the contact form
   */
  const acceptCookiesAndLoadRecaptcha = (): void => {
    setCookiesAccepted(true)
    Cookies.set("AllowWBCRDnCookies", true)
    loadReCaptcha(siteKey)
    setVersion(version + 1)
  }

  /**
   * Helper to return the correct inputs for a {@link ContactType}
   *
   * @param type
   * @returns JSX.Element of correct inputs
   */
  const getCorrectOptionalFields = (type: ContactType): JSX.Element => {
    switch (type) {
      case ContactType.BOUT:
        return (
          <>
            <div className="contact-input-wrapper">
              <input
                type="text"
                placeholder="Name des Teams"
                value={currentMessage.bout.name}
                onChange={event =>
                  setCurrentMessage({
                    ...currentMessage,
                    bout: {
                      ...currentMessage.bout,
                      name: event.target.value,
                    },
                  })
                }
              />
              <div className="contact-placeholder" />
            </div>
            <div className="contact-input-wrapper">
              <input
                type="date"
                placeholder="Wann"
                value={currentMessage.bout.date}
                onChange={event =>
                  setCurrentMessage({
                    ...currentMessage,
                    bout: {
                      ...currentMessage.bout,
                      date: event.target.value,
                    },
                  })
                }
              />
              <input
                type="text"
                placeholder="Wo"
                value={currentMessage.bout.where}
                onChange={event =>
                  setCurrentMessage({
                    ...currentMessage,
                    bout: {
                      ...currentMessage.bout,
                      where: event.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="contact-input-wrapper checkbox-input-wrapper">
              <Checkbox
                checked={currentMessage.bout.allGender}
                onChange={() =>
                  setCurrentMessage({
                    ...currentMessage,
                    bout: {
                      ...currentMessage.bout,
                      allGender: !currentMessage.bout.allGender,
                    },
                  })
                }
                label="all gender"
              />
              <Checkbox
                checked={currentMessage.bout.team}
                onChange={() =>
                  setCurrentMessage({
                    ...currentMessage,
                    bout: {
                      ...currentMessage.bout,
                      team: !currentMessage.bout.team,
                    },
                  })
                }
                label="Team"
              />
            </div>
            <div className="contact-input-wrapper checkbox-input-wrapper">
              <Checkbox
                checked={currentMessage.bout.player}
                onChange={() =>
                  setCurrentMessage({
                    ...currentMessage,
                    bout: {
                      ...currentMessage.bout,
                      player: !currentMessage.bout.player,
                    },
                  })
                }
                label="all gender player"
              />
              <Checkbox
                checked={currentMessage.bout.woman}
                onChange={() =>
                  setCurrentMessage({
                    ...currentMessage,
                    bout: {
                      ...currentMessage.bout,
                      woman: !currentMessage.bout.woman,
                    },
                  })
                }
                label="only woman"
              />
            </div>
            <div className="contact-input-wrapper checkbox-input-wrapper">
              <Checkbox
                checked={currentMessage.bout.man}
                onChange={() =>
                  setCurrentMessage({
                    ...currentMessage,
                    bout: {
                      ...currentMessage.bout,
                      man: !currentMessage.bout.man,
                    },
                  })
                }
                label="only man"
              />
              <div className="contact-placeholder" />
            </div>
          </>
        )
      case ContactType.LEITUNG:
        return <></>
      case ContactType.INFO:
        return (
          <div className="contact-input-wrapper">
            <input
              type="text"
              placeholder={isEnglish ? "Subject" : "Betreff"}
              value={currentMessage.question}
              onChange={event =>
                setCurrentMessage({
                  ...currentMessage,
                  question: event.target.value,
                })
              }
            />
            <div className="contact-placeholder" />
          </div>
        )
      case ContactType.TRAINING:
      default:
        return (
          <div className="contact-input-wrapper">
            <input
              type="text"
              placeholder={isEnglish ? "mobile number" : "Handynummer"}
              value={currentMessage.training.mobile}
              onChange={event =>
                setCurrentMessage({
                  ...currentMessage,
                  training: {
                    ...currentMessage.training,
                    mobile: event.target.value,
                  },
                })
              }
            />
            <input
              type="text"
              placeholder={isEnglish ? "Shoe size" : "Schuhgröße"}
              value={currentMessage.shoeSize}
              onChange={event =>
                setCurrentMessage({
                  ...currentMessage,
                  training: {
                    ...currentMessage.training,
                    shoeSize: event.target.value,
                  },
                })
              }
            />
          </div>
        )
    }
  }

  return (
    <>
      <div className="contact-component-wrapper">
        <div className="contact-column contact-column-left-side">
          <h2 id="contact">{isEnglish ? "Contact" : "Kontakt"}</h2>
          <p className="contact-info-text">
            {isEnglish ? (
              <>
                We are always looking for new players who are not afraid to come
                home with bruises sometimes.
                <br />
                If you are interested, simply contact us:
              </>
            ) : (
              <>
                Es werden immer neue SpielerInnen gesucht, die keine Angst haben
                auch mal mit blauen Flecken nach Hause zu kommen.
                <br />
                Bei Interesse melde dich einfach:
              </>
            )}
          </p>
          <div className="contact-social-media-wrapper">
            <a
              href="https://m.facebook.com/barockcity/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={FacebookImage} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/barockcityrollerderby/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={InstagramImage} alt="Instagram" />
            </a>
          </div>
          <div className="contact-mtv-wrapper">
            <a
              href="https://mtv-ludwigsburg.de/project/rollerderby/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={MTVLogo} alt="MTV" />
            </a>
          </div>
        </div>
        <div
          className="contact-column"
          style={{ paddingTop: "57px" }}
          key={version}
        >
          {cookiesAccepted ? (
            <>
              <div className="contact-input-wrapper">
                <input
                  type="text"
                  placeholder={isEnglish ? "Givenname" : "Vorname"}
                  value={currentMessage.firstname}
                  onChange={event =>
                    setCurrentMessage({
                      ...currentMessage,
                      firstname: event.target.value,
                    })
                  }
                />
                <input
                  type="text"
                  placeholder={isEnglish ? "Lastname" : "Nachname"}
                  value={currentMessage.lastname}
                  onChange={event =>
                    setCurrentMessage({
                      ...currentMessage,
                      lastname: event.target.value,
                    })
                  }
                />
              </div>
              <div className="contact-input-wrapper">
                <input
                  type="mail"
                  placeholder={isEnglish ? "Mail - Adress" : "E-Mail-Adresse"}
                  value={currentMessage.mail}
                  onChange={event =>
                    setCurrentMessage({
                      ...currentMessage,
                      mail: event.target.value,
                    })
                  }
                />
                <input
                  type="tel"
                  placeholder={isEnglish ? "phone" : "Telefon"}
                  value={currentMessage.phone}
                  onChange={event =>
                    setCurrentMessage({
                      ...currentMessage,
                      phone: event.target.value,
                    })
                  }
                />
              </div>
              <div className="contact-input-wrapper">
                <select
                  value={currentSelection}
                  onChange={(event: any) =>
                    setCurrentSelection(event.target.value)
                  }
                >
                  <option value={ContactType.TRAINING}>
                    {isEnglish
                      ? "Training / Participation"
                      : "Training / Mitmachen"}
                  </option>
                  <option value={ContactType.BOUT}>
                    {isEnglish
                      ? "Bout requests / game requests"
                      : "Boutanfragen / Spielanfragen"}
                  </option>
                  <option value={ContactType.INFO}>
                    {isEnglish
                      ? "Information / Press"
                      : "Informationen / Presse"}
                  </option>
                  <option value={ContactType.LEITUNG}>
                    {isEnglish
                      ? "Contact the department management"
                      : "Kontakt zur Abteilungsleitung"}
                  </option>
                </select>
                <div className="contact-placeholder" />
              </div>
              {getCorrectOptionalFields(currentSelection)}
              <textarea
                value={currentMessage.message}
                onChange={event =>
                  setCurrentMessage({
                    ...currentMessage,
                    message: event.target.value,
                  })
                }
                rows={7}
                placeholder={
                  isEnglish
                    ? "Enter your message here."
                    : "Geben Sie hier Ihre Nachricht ein."
                }
              ></textarea>
              <button
                onClick={() => (isSending ? {} : sendMessage(currentSelection))}
              >
                {isSending
                  ? isEnglish
                    ? "is sending"
                    : "wird gesendet"
                  : isEnglish
                  ? "Send"
                  : "Abschicken"}
              </button>
              {showError && (
                <>
                  <p className="error-text">
                    {!checkIfStringIsValid(currentMessage.mail) &&
                      (isEnglish
                        ? "Please enter an eMail adress"
                        : "Bitte geben Sie eine E-Mail-Adresse ein")}
                  </p>{" "}
                  <p className="error-text">
                    {!checkIfStringIsValid(currentMessage.message) &&
                      (isEnglish
                        ? "Please enter a message"
                        : "Bitte geben Sie eine Nachricht ein")}
                  </p>{" "}
                  <p className="error-text">
                    {checkIfStringIsValid(currentMessage.mail) &&
                      checkIfStringIsValid(currentMessage.message) &&
                      (isEnglish
                        ? "An error has occurred. Please try again."
                        : "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.")}
                  </p>
                </>
              )}
            </>
          ) : (
            <div className="please-accept-the-cookies">
              <div>
                Akzeptieren Sie bitte die{" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => acceptCookiesAndLoadRecaptcha()}
                >
                  Cookies
                </span>{" "}
                oder schreib uns eine E-Mail an:{" "}
                <a href="mailto:info@barockcity.de">info@barockcity.de</a>
              </div>
            </div>
          )}
        </div>
      </div>

      <ReCaptcha
        sitekey={siteKey}
        action="BCRD_Landing"
        ref={recaptchaRef}
        verifyCallback={recaptchaToken => verifyCallback(recaptchaToken)}
      />
      <CookieConsent
        style={
          cookiesAccepted
            ? { display: "none" }
            : {
                backgroundColor: "#a09d9d",
                borderTop: "1px solid #272727",
              }
        }
        enableDeclineButton
        cookieName="AllowBCRDCookies"
        setDeclineCookie={false}
        buttonText={"COOKIES ANNEHMEN"}
        declineButtonText={"COOKIES ABLEHNEN"}
        sameSite="strict"
        onAccept={() => acceptCookiesAndLoadRecaptcha()}
        buttonStyle={{ backgroundColor: "#006633", color: "#f3f3f4" }}
        declineButtonStyle={{ backgroundColor: "#e22029" }}
      >
        {isEnglish
          ? "We use cookies on our website. To increase the security of this website against bots we use the service Google Recaptcha Invisible. Cookies are required for this. If you agree here, cookies will be set according to our privacy policy. A refusal can affect the functionality of the site."
          : "Wir nutzen Cookies auf unserer Website. Um die Sicherheit dieser Website gegen Bots zu erhöhen nutzen wir den Dienst Google Recaptcha Invisible. Hierfür werden Cookies benötigt. Wenn Sie hier zustimmen werden Cookies gemäß unserer Datenschutzbestimmung gesetzt. Ein Abhlehnen kann den Funktionsumfang der Seite beeinträchtigen."}
      </CookieConsent>
    </>
  )
}

export default ContactComponent
